import React, { useState } from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"
import { Container, Row, Col, Image } from "react-bootstrap"

import { useGlobalState } from "../../global/global"
import { getLanguageFile } from "../../global/langauge.helper"
import { TextFormater } from "../../global/textFormater"

import ClarificaitonModal from "../ClarificationModal"

import "./styles.scss"
import ETETECHNOLOGY_LOGO from "../../../assets/images/EteTechnology_LOGO_YATAY.png"

// import ETECUBE_LOGO from "../../../assets/images/etecube.png"
import LINKEDIN_ICON from "../../../assets/images/linkedin_icon.png"
import LINKEDIN_DARK_ICON from "../../../assets/images/linkedin_dark_icon.png"

const Footer = ({ extraStyle }) => {
  const [state] = useGlobalState()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Container style={extraStyle} className="footer-container">
        <Row>
          <Col className={"col-lg-4 col-12"}>
            <Image className={"footer-img"} src={ETETECHNOLOGY_LOGO} />
            <p className={"footer-text"}>
              {getLanguageFile(state.language).Footer.footerText}
            </p>
            <p className="footer-text">ISO 9001:2015 | ISO 10007:2017</p>
            <h5 className={"footer-text-2"}>
              {getLanguageFile(state.language).Footer.copyRight}
            </h5>
          </Col>
          <Col className={"offset-lg-2 col-lg-2 col-4"}>
            <div className="footer-headings-etecube-container">
              <p className={"footer-headings-etecube-text"}>ete technology</p>
              <p className="footer-copy-right">®</p>
            </div>
            <Link to="/about-us" style={{ textDecoration: "none" }}>
              <h4 className={"footer-pages-text-first"}>
                {getLanguageFile(state.language).Footer.aboutUs}
              </h4>
            </Link>
            <Link to="/about-us/#News" style={{ textDecoration: "none" }}>
              <h4 className={"footer-pages-text"}>
                {getLanguageFile(state.language).Footer.news}
              </h4>
            </Link>
            <Link to="/contact-us" style={{ textDecoration: "none" }}>
              <h4 className={"footer-pages-text"}>
                {getLanguageFile(state.language).Footer.contactUs}
              </h4>
            </Link>
            <Link to="/clarification" style={{ textDecoration: "none" }}>
              <h4 className={"footer-pages-text"}>
                {getLanguageFile(state.language).Footer.clarification}
              </h4>
            </Link>
          </Col>
          <Col className={"col-lg-2 col-4"}>
            {" "}
            <h5 className={"footer-headings-text"}>
              {getLanguageFile(state.language).Footer.products}
            </h5>
            <Link to="/products/hymots" style={{ textDecoration: "none" }}>
              <div className="footer-hymots-nginr-container hymots">
                <p className="footer-product-name">hymots</p>
                <p>{TextFormater("<0>®</0>")}</p>
              </div>
            </Link>
            {/* <Link to="/products/nginrS" style={{ textDecoration: "none" }}>
              <div className="footer-hymots-nginr-container">
                <p className="footer-product-name">nginr</p>
                <p>{TextFormater("<0>®</0>")}</p>
                <p className="footer-product-name">s</p>
              </div>
            </Link> */}
            <Link to="/products/nginr" style={{ textDecoration: "none" }}>
              <div className="footer-hymots-nginr-container">
                <p className="footer-product-name">nginr</p>
                <p>{TextFormater("<0>®</0>")}</p>
                {/* <p className="footer-product-name">m</p> */}
              </div>
            </Link>
            <Link to="/products/etecube" style={{ textDecoration: "none" }}>
              <div className="footer-etesolutions-container">
                <p className="footer-product-name">etecube</p>
                <p>{TextFormater("<0>®</0>")}</p>
              </div>
            </Link>
          </Col>
          <Col className={"col-lg-2 col-4"}>
            {" "}
            <h5 className={"footer-headings-text"}>
              {getLanguageFile(state.language).Footer.followUs}
            </h5>
            <Link
              to={"https://www.linkedin.com/company/etesolutions/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                className={"social_media_icon"}
                src={LINKEDIN_ICON}
                style={{ marginTop: "13px" }}
                onMouseOver={e => (e.currentTarget.src = LINKEDIN_DARK_ICON)}
                onMouseOut={e => (e.currentTarget.src = LINKEDIN_ICON)}
              />
            </Link>
          </Col>
        </Row>
      </Container>
      <ClarificaitonModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
      />
    </>
  )
}

Footer.propTypes = {
  extraStyle: PropTypes.string,
}

Footer.defaultProps = {
  Footer: "",
}
export default Footer
